import {
  Outlet,
  useRouteError,
  isRouteErrorResponse,
  useLoaderData,
  useNavigation,
  useFetchers,
  useRevalidator } from
"@remix-run/react";

import { links as dragTableLinks } from "./features/Recipes/Recipe/Ingredients/components/IngredientsDragTable";
import {
  globalStyles,
  previewStyles,
  progressStyles,
  tailwindStyles } from
"./styles";

import { loadRouteData } from "./loaders/root/rootLoader.server";
import MainLayout from "./layouts/MainLayout";
import Document from "./layouts/Document";
import ErrorWrapper from "./components/ErrorWrapper";
import { useNProgress, useToast } from "./hooks";
import { getErrorMessage, logRequest } from "./utils/utils";
import useSetDataToLocalStorage from "./hooks/useSetTokenToLS";
import Providers from "./utils/Providers";

export default function App() {
  const { isCustomerPreview, isLoginPath, token, ENV, userInfo } =
  useLoaderData();
  let transition = useNavigation();
  let fetchers = useFetchers();
  const revalidator = useRevalidator();

  useSetDataToLocalStorage("token", token);
  useNProgress(transition, fetchers, revalidator);

  if (isCustomerPreview || isLoginPath) {
    return (
      <Document isPreview={isCustomerPreview} ENV={ENV}>
        <Outlet />
      </Document>);

  }

  return (
    <Providers userInfo={userInfo}>
      <Document ENV={ENV}>
        <MainLayout />
        {/* Can be useful for debugging react-query related issues: */}
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </Document>
    </Providers>);

}

export const ErrorBoundary = () => {
  const error = useRouteError();

  // when true, = `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    const errorMessage = getErrorMessage(error);

    return (
      <Document title={`${error.status}`}>
        <ErrorWrapper message={errorMessage} />
      </Document>);

  }

  let errorMessage = "Unknown error";
  if (error instanceof Error) {
    errorMessage = error.message;
    logRequest(error);
  }

  // Throughout the codebase, the `error` field is used to store error messages
  if ("error" in error) {
    errorMessage = error.error;
  }

  return (
    <Document title="An Error Occurred">
      <ErrorWrapper message={errorMessage} />
    </Document>);

};

export function links() {
  return [
  ...dragTableLinks(),
  { rel: "icon", href: "/favicon.ico" },
  { rel: "apple-touch-icon", href: "./assets/KptnCookLogo.png" },
  { rel: "manifest", href: "/manifest.json" },
  { rel: "stylesheet", href: globalStyles },
  { rel: "stylesheet", href: tailwindStyles },
  { rel: "stylesheet", href: previewStyles },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/icon?family=Material+Icons"
  },
  {
    rel: "stylesheet",
    href: "https://unpkg.com/react-day-picker@7.4.10/lib/style.css"
  },
  { rel: "stylesheet", href: progressStyles }];

}

export let loader = loadRouteData;